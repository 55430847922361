<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.show" />

    <b-row v-else class="justify-content-md-center">
      <b-col md="12">
        <final-reviews :finalReviews="finalReviewsData" />
        <service-request :serviceRequest="finalReviewsData.service_request" />
        <service-request-review
          :serviceRequestReview="finalReviewsData.service_request_review"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import Error from "@/views/components/logic/error.vue";
import Loading from "@/views/components/logic/loading.vue";
import FinalReviews from "./finalReviews.vue";
import ServiceRequestReview from "./serviceRequestReview.vue";
import ServiceRequest from "./serviceRequest.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";

export default {
  components: {
    BRow,
    BCol,
    Loading,
    Error,
    FinalReviews,
    ServiceRequestReview,
    ServiceRequest,
    ContentNotView,
  },
  data() {
    return {
      finalReviewsData: null,
      isLoading: true,
      isError: false,
      subPermission: {},
    };
  },
  beforeMount() {
    this.$http
      .get(
        `admin/serviceRequests/${this.$route.params.id}/finalReviews/${this.$route.params.techId}`
      )
      .then((res) => {
        this.finalReviewsData = res.data.data;
        

        setTimeout(() => {
          this.isLoading = false;
          this.isError = false;
          store.dispatch("GET_PERMISSION", "finalReviews");
          this.subPermission = store.state.permissions.sub;
        }, 1000);
      })
      .catch((err) => {
        this.isLoading = false;
        this.isError = true;

        console.log(err);
      });
  },
};
</script>

<style></style>
