const dateTimeInFormat = (timestamps) => {
  
  if(!timestamps) return "-"
  
  const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
  ];

  const dateObj = new Date(timestamps);
  let date = dateObj.getDate();
  if (date < 10) {
      date = "0" + date;
  }

  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();

  let hours = dateObj.getHours();
  if (hours < 10) {
      hours = "0" + hours;
  }

  let minutes = dateObj.getMinutes();
  if (minutes < 10) {
      minutes = "0" + minutes;
  }

  let seconds = dateObj.getSeconds();
  if (seconds < 10) {
      seconds = "0" + seconds;
  }
  return (
      date +
      "/" +
      month +
      "/" +
      year
  );
};

export default dateTimeInFormat

