<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col md="12">
        <!--  -->
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12">
                <h4>
                  {{ $t("g.serviceRequestReview") }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4 mt-2">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.is_cert_request_accepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        serviceRequestReview.is_cert_request_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>

                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.is_shipping_accepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        serviceRequestReview.is_shipping_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">
                      {{ $t("g.is_comercial_record_accepted") }}
                    </h5>
                    <h5 class="mb-0 t_1">
                      {{
                        serviceRequestReview.is_comercial_record_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.is_supplying_accepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        serviceRequestReview.is_supplying_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.is_factory_accepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        serviceRequestReview.is_factory_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.submitDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(serviceRequestReview.submit_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BMedia,
} from "bootstrap-vue";

import dateTimeInFormat from "@/libs/format-date";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCol,
    BMedia,
  },
  props: {
    serviceRequestReview: {
      type: {},
      default: {},
    },
  },
  data() {
    return {
      formatDate: dateTimeInFormat,
    };
  },
};
</script>

<style>
.t_1 {
  margin-top: 0.4rem;
}
</style>
