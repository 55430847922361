<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col md="12">
        <!--  -->
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12">
                <h4>
                  {{ $t("g.serviceRequest") }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.serviceCode") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ serviceRequest.service_code }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="PackageIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.productName") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ serviceRequest.prodcut_name }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.status") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ serviceRequest.stauts }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.isReviewAccepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        serviceRequest.is_review_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>

                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.isClientAccepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        serviceRequest.is_client_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.isTechReviewAccepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        serviceRequest.is_tech_review_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.is_final_review_accepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        serviceRequest.is_final_review_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- ** -->
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12">
                <h4>
                  {{ $t("g.theDates") }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.clientActionDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(serviceRequest.client_action_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>

                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.sentForTechDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(serviceRequest.sent_for_tech_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.sentForTechReviewDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(serviceRequest.sent_for_tech_review_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.reviewActionDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(serviceRequest.review_action_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.techReviewActionDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(serviceRequest.tech_review_action_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.sentForFinalReviewDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(serviceRequest.sent_for_final_review_date)
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.finalReviewActionDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(serviceRequest.final_review_action_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.receiveDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(serviceRequest.receive_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.excpectedReviewEndDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(serviceRequest.excpected_review_end_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.submitDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(serviceRequest.submit_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.closedDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(serviceRequest.closed_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BMedia,
} from "bootstrap-vue";

import dateTimeInFormat from "@/libs/format-date";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCol,
    BMedia,
  },
  props: {
    serviceRequest: {
      type: {},
      default: {},
    },
  },
  data() {
    return {
      formatDate: dateTimeInFormat,
    };
  },
};
</script>

<style>
.t_1 {
  margin-top: 0.4rem;
}
</style>
